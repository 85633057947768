var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-dialog", {
    attrs: { scrollable: "", "max-width": _vm.maxWidth },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "" } },
                    [
                      _c(
                        "v-toolbar-title",
                        [
                          _vm.icon
                            ? _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(_vm._s(_vm.icon)),
                              ])
                            : _vm._e(),
                          _vm._v(" " + _vm._s(_vm.title) + " "),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _vm._t("toolbar-append"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._t("default"),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }