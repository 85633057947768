import { render, staticRenderFns } from "./ThumbsButtons.vue?vue&type=template&id=5834d76a&scoped=true&"
import script from "./ThumbsButtons.vue?vue&type=script&lang=ts&"
export * from "./ThumbsButtons.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5834d76a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/source/client-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5834d76a')) {
      api.createRecord('5834d76a', component.options)
    } else {
      api.reload('5834d76a', component.options)
    }
    module.hot.accept("./ThumbsButtons.vue?vue&type=template&id=5834d76a&scoped=true&", function () {
      api.rerender('5834d76a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Feedback/ThumbsButtons.vue"
export default component.exports