var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "fill-height d-flex flex-column",
      attrs: { elevation: "3", rounded: "", loading: _vm.loading },
    },
    [
      _c(
        "v-card-title",
        [
          _vm.icon
            ? _c("v-icon", { attrs: { left: "" } }, [_vm._v(_vm._s(_vm.icon))])
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.title))]),
        ],
        1
      ),
      _c("v-card-subtitle", [_c("span", [_vm._v(_vm._s(_vm.subtitle))])]),
      _c("v-divider", { staticClass: "mx-3" }),
      _vm.loaded
        ? [_vm._t("content")]
        : _vm.loading
        ? _c(
            "v-card-text",
            { staticClass: "flex-grow-1 align-content-center text-center" },
            [_vm._t("content-loading")],
            2
          )
        : _c(
            "v-card-text",
            { staticClass: "flex-grow-1 align-content-center text-center" },
            [_vm._t("content-nodata")],
            2
          ),
      _vm.$slots.actions
        ? _c("v-card-actions", [_vm._t("actions")], 2)
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }