
















































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Carousel3d, Slide } from 'vue-carousel-3d';

/**
 * Global Configuration Props
 * 
 * @prop {Boolean} autoplay - Flag to enable autoplay (default: false)
 * @prop {Number} autoplayTimeout - Time elapsed before advancing slide (default: 2000)
 * @prop {Boolean} autoplayHoverPause - Flag to pause autoplay on hover (default: false)
 * @prop {Number} count - Number of slides (required for dynamic slides) (default: 0)
 * @prop {Number} perspective - Slides perspective position (default: 35)
 * @prop {Number} display - Number of slides displayed on each page (default: 5)
 * @prop {String} bias - When displaying even number of slides chose bigger side (default: 'left')
 * @prop {Boolean} loop - Enable carousel infinite loop (default: true)
 * @prop {Number} animationSpeed - Animation between slides in milliseconds (default: 500)
 * @prop {String} dir - Animation direction (default: 'rtl')
 * @prop {Number} width - Slide width displayed in center of carousel (default: 360)
 * @prop {Number} height - Slide height displayed in center of carousel (default: 270)
 * @prop {Number} border - Slide border size in pixels (default: 0)
 * @prop {Number|String} space - Space between slides in pixels (default: 'auto')
 * @prop {Number} startIndex - Start slide index. First slide has 0 index (default: 0)
 * @prop {Boolean} clickable - Enable navigation by clicking on slide (default: true)
 * @prop {Number} minSwipeDistance - Minimum distance in pixels to swipe before a slide advance is triggered (default: 10)
 * @prop {Number} inverseScaling - Slide inverse scaling (default: 300)
 * @prop {Boolean} controlsVisible - Show controls (left/right arrow) (default: false)
 * @prop {String} controlsPrevHtml - Set previous arrow string (default: '&lsaquo;')
 * @prop {String} controlsNextHtml - Set next arrow string (default: '&rsaquo;')
 * @prop {Number} controlsWidth - Set arrows target width area in pixels (default: 50)
 * @prop {Number} controlsHeight - Set arrows target height area in pixels (default: 60)
 */

@Component({
    model: {
        prop: "model",
        event: "modelChanged"
    },
    components: {
        Carousel3d,
        Slide
    }
})
export default class Carousel3dSlider extends Vue {
    // Fields

    // Component Properties
    @Prop({ required: false, type: Number, default: 0 })
    public model!: number;

    @Prop({ type: Boolean, default: false }) 
    public autoplay!: boolean;
    
    @Prop({ type: Number, default: 2000 }) 
    public autoplayTimeout!: number;

    @Prop({ type: Boolean, default: false }) 
    public autoplayHoverPause!: boolean;

    @Prop({ type: Number, default: 0 }) 
    public count!: number;

    @Prop({ type: Number, default: 35 }) 
    public perspective!: number;

    @Prop({ type: Number, default: 5 }) 
    public display!: number;

    @Prop({ type: String, default: 'left' }) 
    public bias!: string;

    @Prop({ type: Boolean, default: true }) 
    public loop!: boolean;

    @Prop({ type: Number, default: 500 }) 
    public animationSpeed!: number;

    @Prop({ type: String, default: 'rtl' }) 
    public dir!: string;

    @Prop({ type: Number, default: 360 }) 
    public width!: number;

    @Prop({ type: Number, default: 270 }) 
    public height!: number;

    @Prop({ type: Number, default: 0 }) 
    public border!: number;

    @Prop({ type: Number }) 
    public space!: number;

    @Prop({ type: Number, default: 0 }) 
    public startIndex!: number;

    @Prop({ type: Boolean, default: true }) 
    public clickable!: boolean;

    @Prop({ type: Number, default: 10 }) 
    public minSwipeDistance!: number;

    @Prop({ type: Number, default: 300 }) 
    public inverseScaling!: number;

    @Prop({ type: Boolean, default: false }) 
    public controlsVisible!: boolean;

    @Prop({ type: String, default: '&lsaquo;' }) 
    public controlsPrevHtml!: string;

    @Prop({ type: String, default: '&rsaquo;' }) 
    public controlsNextHtml!: string;

    @Prop({ type: Number, default: 50 }) 
    public controlsWidth!: number;

    @Prop({ type: Number, default: 60 }) 
    public controlsHeight!: number;

    // Watcher
    @Watch('model')
    public onModelChanged(value: number) {
        (this.$refs.carousel as any)?.goSlide(value);
    }

    // Lifecycle Hooks
    
    // Methods
    public onAfterSlideChange(index: number) {
        this.$emit('modelChanged', index);
        this.$emit('afterSlideChange', index);
    }
    public onBeforeSlideChange(index: number) {
        this.$emit('beforeSlideChange', index);
    }
    public onLastSlide(index: number) {
        this.$emit('lastSlide', index);
    }
}
