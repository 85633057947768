var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    { staticStyle: { "min-height": "100%" } },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "sticky-content",
          staticStyle: { opacity: "0.7" },
          attrs: {
            clipped: "",
            permanent: "",
            absolute: "",
            dark: "",
            "mini-variant": "",
            color: "background-dark",
          },
          model: {
            value: _vm.navigationDrawer,
            callback: function ($$v) {
              _vm.navigationDrawer = $$v
            },
            expression: "navigationDrawer",
          },
        },
        [
          _c(
            "v-list",
            {
              staticClass: "vertical-center",
              attrs: { dense: "", color: "transparent" },
            },
            [
              _c(
                "v-list-item",
                {
                  attrs: {
                    to: { name: "MyBraendz", hash: "#bookmarks" },
                    ripple: false,
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("fa-light fa-star")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: {
                    to: { name: "MyBraendz", hash: "#storedBrandQueries" },
                    ripple: false,
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("fa-light fa-robot")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "section",
        { attrs: { id: "bookmarks" } },
        [
          _c(
            "v-container",
            { staticClass: "left-56", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("SectionHeader", {
                        staticClass: "font-weight-bold",
                        attrs: {
                          title: _vm.$t("myBraendz.myBrands"),
                          icon: "fa-light fa-star",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.brandBookmarkContainers.length > 0
            ? _vm._l(_vm.brandBookmarkContainers, function (container, index) {
                return _c("BrandBookmarkContainerCard", {
                  key: _vm.getIdOrIndex(container.object, index),
                  attrs: { container: container, indent: 56 },
                })
              })
            : [
                _c(
                  "v-container",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      { attrs: { justify: "center" } },
                      [
                        _c("v-col", { attrs: { cols: "auto" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("myBraendz.noBookmarks")) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c(
        "section",
        { attrs: { id: "storedBrandQueries" } },
        [
          _c(
            "v-container",
            { staticClass: "left-56", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("SectionHeader", {
                        staticClass: "font-weight-bold",
                        attrs: {
                          title: _vm.$t("myBraendz.myAgents"),
                          icon: "fa-light fa-robot",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.storedBrandQueries.list.length > 0
            ? _vm._l(
                _vm.storedBrandQueries.list,
                function (storedBrandQuery, index) {
                  return _c(
                    "section",
                    { key: _vm.getIdOrIndex(storedBrandQuery.object, index) },
                    [
                      storedBrandQuery.object || storedBrandQuery.isBusy
                        ? _c("StoredBrandQueryCard", {
                            attrs: {
                              storedBrandQuery: storedBrandQuery,
                              indent: 56,
                            },
                            on: {
                              storedBrandQueryChanged: function ($event) {
                                return _vm.onStoredBrandQueryChanged(
                                  storedBrandQuery,
                                  $event
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }
              )
            : !_vm.storedBrandQueries.isBusy
            ? [
                _c(
                  "v-container",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      { attrs: { justify: "center" } },
                      [
                        _c("v-col", { attrs: { cols: "auto" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("myBraendz.noAgents")) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("v-progress-linear", {
        attrs: {
          indeterminate: "",
          fixed: "",
          bottom: "",
          active: _vm.storedBrandQueries.isBusy,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }