var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.brandQueryHistoryItems.list.length > 0
    ? _c(
        "VueSlickCarousel",
        {
          staticClass: "align-height",
          attrs: {
            arrows: false,
            accessibility: "",
            swipeToSlide: "",
            dots: "",
            infinite: "",
            focusOnSelect: "",
            centerMode: "",
            slidesToShow: _vm.itemsToShow,
            slidesToScroll: 1,
          },
        },
        _vm._l(
          _vm.brandQueryHistoryItems.list,
          function (brandQueryHistoryItem, index) {
            return _c(
              "div",
              {
                key: _vm.getIdOrIndex(brandQueryHistoryItem.object, index),
                staticClass: "fill-height pa-3",
              },
              [
                _c("BrandQueryHistoryItemTile", {
                  staticClass: "fill-height",
                  attrs: {
                    item: brandQueryHistoryItem,
                    storedBrandQuery: _vm.storedBrandQuery,
                  },
                }),
              ],
              1
            )
          }
        ),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }