var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "contents" } },
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            top: _vm.tooltip === "top",
            bottom: _vm.tooltip === "bottom",
            left: _vm.tooltip === "left",
            right: _vm.tooltip === "right",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attr = ref.attr
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.feedbackRequest.object ||
                                _vm.feedbackRequest.object.positive === true,
                              expression:
                                "!feedbackRequest.object || feedbackRequest.object.positive === true",
                            },
                          ],
                          attrs: {
                            disabled:
                              _vm.disabled || !!_vm.feedbackRequest.object,
                            icon: "",
                            "x-small": _vm.xSmall,
                            small: _vm.small,
                            medium: _vm.medium,
                            large: _vm.large,
                            xLarge: _vm.xLarge,
                          },
                          on: {
                            click: function () {
                              return _vm.submit(true)
                            },
                          },
                        },
                        "v-btn",
                        attr,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            "x-small": _vm.xSmall,
                            small: _vm.small,
                            medium: _vm.medium,
                            large: _vm.large,
                            xLarge: _vm.xLarge,
                          },
                        },
                        [_vm._v("fa-light fa-thumbs-up")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_vm._v(" " + _vm._s(_vm.$t("thumbsButtons.positive")) + " ")]
      ),
      _c(
        "v-tooltip",
        {
          attrs: {
            top: _vm.tooltip === "top",
            bottom: _vm.tooltip === "bottom",
            left: _vm.tooltip === "left",
            right: _vm.tooltip === "right",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attr = ref.attr
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.feedbackRequest.object ||
                                _vm.feedbackRequest.object.positive === false,
                              expression:
                                "!feedbackRequest.object || feedbackRequest.object.positive === false",
                            },
                          ],
                          attrs: {
                            disabled:
                              _vm.disabled || !!_vm.feedbackRequest.object,
                            icon: "",
                            "x-small": _vm.xSmall,
                            small: _vm.small,
                            medium: _vm.medium,
                            large: _vm.large,
                            xLarge: _vm.xLarge,
                          },
                          on: {
                            click: function () {
                              return _vm.submit(false)
                            },
                          },
                        },
                        "v-btn",
                        attr,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            "x-small": _vm.xSmall,
                            small: _vm.small,
                            medium: _vm.medium,
                            large: _vm.large,
                            xLarge: _vm.xLarge,
                          },
                        },
                        [_vm._v("fa-light fa-thumbs-down")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_vm._v(" " + _vm._s(_vm.$t("thumbsButtons.negative")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }