var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { tile: "", elevation: 20 } },
    [
      _c(
        "v-container",
        {
          style: { "padding-left": _vm.indent + 12 + "px" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: {
                        elevation: "0",
                        short: "",
                        color: "transparent",
                        dense: "",
                      },
                    },
                    [
                      _vm.container.object
                        ? _c("v-toolbar-title", [
                            _vm._v(_vm._s(_vm.container.object.name)),
                          ])
                        : _c("v-toolbar-title", [_vm._v("Loading...")]),
                      _c("v-spacer"),
                      _c("ConfirmationPopup", {
                        attrs: {
                          yes: "",
                          cancel: "",
                          question: "",
                          title: "Löschen",
                          text: "Möchten Sie die Liste unwiderruflich löschen?",
                        },
                        on: {
                          yes: function ($event) {
                            return _vm.deleteContainer()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _vm.container.object && _vm.container.object.id
                                  ? _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { plain: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v("fa-light fa-trash-can"),
                                        ]),
                                        _c("span", [_vm._v("Delete")]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.showDeleteContainerConfirmation,
                          callback: function ($$v) {
                            _vm.showDeleteContainerConfirmation = $$v
                          },
                          expression: "showDeleteContainerConfirmation",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-block" },
        [
          _vm.container.isBusy
            ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
            : _vm.container.object
            ? _c("BrandBookmarkContainerSlider", {
                attrs: { container: _vm.container.object },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }