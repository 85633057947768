var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "carousel-3d",
    {
      ref: "carousel",
      attrs: {
        autoplay: _vm.autoplay,
        "autoplay-timeout": _vm.autoplayTimeout,
        "autoplay-hover-pause": _vm.autoplayHoverPause,
        count: _vm.count,
        perspective: _vm.perspective,
        display: _vm.display,
        bias: _vm.bias,
        loop: _vm.loop,
        "animation-speed": _vm.animationSpeed,
        dir: _vm.dir,
        width: _vm.width,
        height: _vm.height,
        border: _vm.border,
        space: _vm.space,
        "start-index": _vm.startIndex,
        clickable: _vm.clickable,
        "min-swipe-distance": _vm.minSwipeDistance,
        "inverse-scaling": _vm.inverseScaling,
        "controls-visible": _vm.controlsVisible,
        "controls-prev-html": _vm.controlsPrevHtml,
        "controls-next-html": _vm.controlsNextHtml,
        "controls-width": _vm.controlsWidth,
        "controls-height": _vm.controlsHeight,
      },
      on: {
        "after-slide-change": _vm.onAfterSlideChange,
        "before-slide-change": _vm.onBeforeSlideChange,
        "last-slide": _vm.onLastSlide,
      },
    },
    [
      _vm._l(_vm.$slots, function (slot, name, index) {
        return [
          slot
            ? _c(
                "slide",
                { key: name, attrs: { index: index } },
                [_vm._t(name)],
                2
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }