var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-dialog", {
    attrs: { scrollable: "", fullscreen: "" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", dense: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-tabs",
                            {
                              attrs: { right: "", "slider-color": "primary" },
                              model: {
                                value: _vm.activeBrandViewTabIndex,
                                callback: function ($$v) {
                                  _vm.activeBrandViewTabIndex = $$v
                                },
                                expression: "activeBrandViewTabIndex",
                              },
                            },
                            [
                              _c(
                                "v-tab",
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      "fa-light fa-distribute-spacing-horizontal"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-tab",
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      "fa-light fa-distribute-spacing-vertical"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-toolbar-items",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _vm.storedBrandQuery &&
                                      _vm.storedBrandQuery.id &&
                                      _vm.brandQueryResult.id
                                        ? _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    dark: "",
                                                    to: {
                                                      name: "StoredBrandQueryResult",
                                                      params: {
                                                        queryId:
                                                          _vm.storedBrandQuery
                                                            .id,
                                                        resultId:
                                                          _vm.brandQueryResult
                                                            .id,
                                                      },
                                                    },
                                                    target: "_blank",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "fa-light fa-arrow-up-right-from-square"
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Open in new window")])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "py-6" },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _vm.storedBrandQuery || _vm.brandQueryResult
                            ? _c(
                                "v-row",
                                [
                                  _vm.storedBrandQuery
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass: "ma-1",
                                              attrs: {
                                                elevation: "3",
                                                color: "white",
                                              },
                                            },
                                            [
                                              _vm.storedBrandQuery.name
                                                ? _c(
                                                    "v-card-title",
                                                    { staticClass: "text-h4" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.storedBrandQuery
                                                              .name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.storedBrandQuery.description
                                                ? _c("v-card-subtitle", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.storedBrandQuery
                                                            .description
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _c(
                                                "v-card-text",
                                                { staticClass: "my-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-center text-center",
                                                    },
                                                    [
                                                      _vm.brandQueryResult
                                                        .totalCount
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mx-5",
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "d-block text-truncate text-h6",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .brandQueryResult
                                                                        .totalCount
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "caption d-block",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Total Results"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.brandQueryResult.items
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mx-5",
                                                              attrs: {
                                                                cols: "6",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "d-block text-truncate text-h6",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .brandQueryResult
                                                                        .items
                                                                        .length
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "caption d-block",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Stored Results"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-tabs-items",
                                    {
                                      staticClass: "pa-1",
                                      model: {
                                        value: _vm.activeBrandViewTabIndex,
                                        callback: function ($$v) {
                                          _vm.activeBrandViewTabIndex = $$v
                                        },
                                        expression: "activeBrandViewTabIndex",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-tab-item",
                                        [
                                          _c("VerticalBrandTileGrid", {
                                            attrs: {
                                              brands:
                                                _vm.brandQueryResult.items,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tab-item",
                                        [
                                          _c("HorizontalBrandTileGrid", {
                                            attrs: {
                                              brands:
                                                _vm.brandQueryResult.items,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tab-item",
                                        [
                                          _c("BrandTable", {
                                            attrs: {
                                              brands:
                                                _vm.brandQueryResult.items,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }