var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { class: _vm.$attrs.class },
    [
      _c(
        "div",
        { staticClass: "section-header__content" },
        [
          _vm.icon
            ? _c("v-icon", { staticClass: "mr-3" }, [_vm._v(_vm._s(_vm.icon))])
            : _vm._e(),
          _c("div", { staticClass: "section-header__title" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
        ],
        1
      ),
      _vm.underlined ? _c("v-divider") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }