var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.debugging
    ? _c(
        "v-layout",
        {
          style: { width: _vm.width },
          attrs: { column: "", "align-center": "" },
        },
        [
          _c("v-flex", { staticClass: "body-1" }, [
            _vm._v(" " + _vm._s(_vm.$n(_vm.value)) + " "),
          ]),
          _vm.title
            ? _c("v-flex", [_vm._v(" " + _vm._s(_vm.title) + " ")])
            : _vm._e(),
        ],
        1
      )
    : _c("vue-speedometer", {
        attrs: {
          fluidWidth: _vm.autoWidth,
          width: _vm.width,
          height: _vm.height,
          minValue: _vm.minValue,
          maxValue: _vm.maxValue,
          ringWidth: 25,
          labelFontSize: "0px",
          valueTextFontSize: "10px",
          valueTextFontWeight: "normal",
          value: _vm.value,
          segments: 11,
          maxSegmentLabels: 0,
          currentValueText: _vm.title,
          startColor: "#33CC33",
          endColor: "#FF0000",
          needleColor: "#041B2E",
          needleHeightRatio: 0.7,
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }