var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-dialog", {
    attrs: { "max-width": "700" },
    scopedSlots: _vm._u(
      [
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [_vm._t("activator", null, null, { on: on, attrs: attrs })]
          },
        },
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", dark: "" } },
                    [
                      _c(
                        "v-toolbar-title",
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("fa-light fa-play"),
                          ]),
                          _c("span", [_vm._v("Execute")]),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.close },
                        },
                        [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pt-6 pb-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.sizes,
                                  "item-text": "text",
                                  "item-value": "size",
                                  label: "Maximum Results",
                                  "persistent-hint": "",
                                },
                                model: {
                                  value: _vm.selectedSize,
                                  callback: function ($$v) {
                                    _vm.selectedSize = $$v
                                  },
                                  expression: "selectedSize",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "pa-0",
                                attrs: { label: "Store results" },
                                model: {
                                  value: _vm.saveResult,
                                  callback: function ($$v) {
                                    _vm.saveResult = $$v
                                  },
                                  expression: "saveResult",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", loading: _vm.executing },
                          on: { click: _vm.execute },
                        },
                        [_vm._v("Execute")]
                      ),
                      _c(
                        "v-btn",
                        { attrs: { text: "" }, on: { click: _vm.close } },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v
      },
      expression: "visible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }