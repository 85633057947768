var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item.isBusy
    ? _c(
        "v-card",
        {
          staticClass: "mx-auto d-flex flex-column rounded-lg",
          attrs: {
            light: "",
            "max-width": "500",
            height: "100%",
            elevation: "3",
            rounded: "",
          },
        },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                {
                  staticStyle: { height: "150px" },
                  attrs: { align: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.item.object
    ? _c(
        "v-card",
        {
          staticClass: "mx-auto d-flex flex-column rounded-lg",
          attrs: {
            light: "",
            "max-width": "500",
            height: "100%",
            elevation: "3",
            rounded: "",
          },
        },
        [
          _c(
            "v-card-title",
            { staticStyle: { display: "block" } },
            [
              _c(
                "v-list-item",
                { attrs: { "two-line": "" } },
                [
                  _vm.item.object.state === "Succeeded"
                    ? _c(
                        "v-list-item-avatar",
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: { "x-large": "", color: "green darken-4" },
                            },
                            [_vm._v("fa-light fa-circle-check")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.item.object.state === "Failed"
                    ? _c(
                        "v-list-item-avatar",
                        [
                          _c(
                            "v-icon",
                            { attrs: { "x-large": "", color: "red darken-4" } },
                            [_vm._v("fa-light fa-circle-exclamation-check")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item-content",
                    [
                      _vm.executionOrCreationTimeUtc
                        ? _c(
                            "v-list-item-title",
                            { staticClass: "text-h5 text-truncate" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$d(
                                      new Date(_vm.executionOrCreationTimeUtc),
                                      "dateTime"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.item.object.state === "Succeeded"
                        ? _c("v-list-item-subtitle", [
                            _vm._v(" Result Available "),
                          ])
                        : _vm.item.object.state === "Failed"
                        ? _c("v-list-item-subtitle", [
                            _vm._v(" Execution Failed "),
                          ])
                        : _c("v-list-item-subtitle", [_vm._v(" Unknown ")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mx-3" }),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                {
                  staticStyle: { height: "150px" },
                  attrs: { align: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { cols: "12" },
                    },
                    [
                      _vm.storedResultsPercentage
                        ? _c(
                            "v-progress-circular",
                            {
                              attrs: {
                                size: 100,
                                width: 15,
                                value: _vm.storedResultsPercentage,
                                color: "primary",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$n(
                                      _vm.storedResultsPercentage / 100,
                                      "percent"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _c(
                            "v-progress-circular",
                            {
                              attrs: {
                                size: 100,
                                width: 15,
                                value: 0,
                                color: "primary",
                              },
                            },
                            [_vm._v(" N.A. ")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mb-2 mx-3" }),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _vm.item.object.storedResultsCount
                      ? _c(
                          "span",
                          { staticClass: "d-block text-truncate body-1" },
                          [_vm._v(_vm._s(_vm.item.object.storedResultsCount))]
                        )
                      : _c(
                          "span",
                          {
                            staticClass:
                              "d-block text-truncate body-1 text--disabled",
                          },
                          [_vm._v("N.A.")]
                        ),
                    _c("span", { staticClass: "caption d-block" }, [
                      _vm._v("Stored Results"),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _vm.item.object.totalResultsCount
                      ? _c(
                          "span",
                          { staticClass: "d-block text-truncate body-1" },
                          [_vm._v(_vm._s(_vm.item.object.totalResultsCount))]
                        )
                      : _c(
                          "span",
                          {
                            staticClass:
                              "d-block text-truncate body-1 text--disabled",
                          },
                          [_vm._v("N.A.")]
                        ),
                    _c("span", { staticClass: "caption d-block" }, [
                      _vm._v("Total Results"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
          _c("v-divider", { staticClass: "my-2 mx-3" }),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    plain: "",
                    disabled:
                      !_vm.item.object.id ||
                      _vm.item.object.state !== "Succeeded",
                    loading: _vm.brandQueryResult.isBusy,
                  },
                  on: { click: _vm.showResult },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("fa-light fa-eye"),
                  ]),
                  _c("span", [_vm._v("Show Results")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.executionOrCreationTimeUtc &&
          _vm.brandQueryResult.object &&
          _vm.storedBrandQuery
            ? _c("BrandQueryResultPopup", {
                attrs: {
                  brandQueryResult: _vm.brandQueryResult.object,
                  storedBrandQuery: _vm.storedBrandQuery,
                  title: _vm.$d(
                    new Date(_vm.executionOrCreationTimeUtc),
                    "dateTime"
                  ),
                },
                model: {
                  value: _vm.showBrandQueryResult,
                  callback: function ($$v) {
                    _vm.showBrandQueryResult = $$v
                  },
                  expression: "showBrandQueryResult",
                },
              })
            : _vm.executionOrCreationTimeUtc && _vm.brandQueryResult.object
            ? _c("BrandQueryResultPopup", {
                attrs: {
                  brandQueryResult: _vm.brandQueryResult.object,
                  title: _vm.$d(
                    new Date(_vm.executionOrCreationTimeUtc),
                    "dateTime"
                  ),
                },
                model: {
                  value: _vm.showBrandQueryResult,
                  callback: function ($$v) {
                    _vm.showBrandQueryResult = $$v
                  },
                  expression: "showBrandQueryResult",
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }