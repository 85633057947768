































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

import PaywallOverlay from '@/components/Features/PaywallOverlay.vue';

@Component({
    components: {
        PaywallOverlay
    },
})
export default class DashboardCard extends Vue {
    // Member
        
    // Properties
    @Prop({ required: true })
    public title!: string;

    @Prop({ required: false })
    public subtitle?: string;

    @Prop({ required: false })
    public icon?: string;

    @Prop({ required: false, default: false, type: Boolean })
    public loading!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public nodata!: boolean;

    @Prop({ required: false })
    public color?: string;

    @Prop({ required: false, default: true, type: Boolean })
    public hasFeature!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public requiredLogin!: boolean;

    // Getter & Setter
    
    // Watcher:

    // Lifecycle Methods:

    // Methods:
}
