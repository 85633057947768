var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.brands.list.length > 0
    ? _c(
        "VueSlickCarousel",
        {
          staticClass: "align-height",
          attrs: {
            arrows: false,
            accessibility: "",
            swipeToSlide: "",
            dots: "",
            infinite: "",
            focusOnSelect: "",
            centerMode: "",
            slidesToShow: _vm.brandItemsToShow,
            slidesToScroll: 1,
          },
        },
        _vm._l(_vm.brands.list, function (brand, index) {
          return _c(
            "div",
            { key: index, staticClass: "fill-height pa-3" },
            [
              _c("VerticalBrandTile", {
                staticClass: "fill-height",
                attrs: {
                  brand: brand.object,
                  loading: brand.isBusy,
                  brandBookmarkContainerId: _vm.container.id,
                },
              }),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }